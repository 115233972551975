<template>
  <div class="wap-shop-info">
    <div class="banner">
      <Swipe ref="swipe" :autoplay="3000" :loop="true" :show-indicators="false">
        <SwipeItem v-for="(item, i) in bannerList" :key="i">
          <img :src="item" alt="" />
        </SwipeItem>
      </Swipe>
    </div>

    <div class="shop-info">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="flex">
        <div class="img">
          <img :src="shopInfo.Logo" alt="" />
        </div>
        <div class="info">
          <div class="name">{{ shopInfo.Name }}</div>
          <div class="desc">{{ shopInfo.Intro }}</div>
        </div>
      </div>

      <div class="collect flex-center" @click="toFollow">
        <i :class="isFollow ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
        {{ isFollow ? $t("yi-shou-cang") : $t("shou-cang") }}
      </div>
    </div>

    <div class="tabs">
      <div class="title-inner flex-center-start">
        <div
          class="tab"
          :class="activeIndex == 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          {{ $t('tui-jian') }}
        </div>
        <div
          class="tab"
          :class="activeIndex == 2 ? 'active' : ''"
          @click="changeTab(2)"
        >
          {{ $t('common.user-tab2') }}
        </div>
      </div>
    </div>

    <div class="center-main">
      <div class="recommend" v-if="activeIndex == 1">
        <List
          v-if="goodsList.length > 0"
          v-model="loading"
          :finished="finished"
          :finished-text="$t('common.noMore')"
          @load="onLoad"
        >
          <goodsList :list="goodsList"></goodsList>
        </List>
      </div>
      <div class="all-goods common-product" v-else>
        <div class="goods-main">
          <div class="flex-center category-line">
            <div class="category-icon" @click="changeCategory">
              <img :src="categoryUrl" alt="" />
            </div>
            <div class="search flex-center-start">
              <i class="el-icon-search"></i>
              <span>{{ $t('sou-suo-dian-pu-nei-shang-pin') }}</span>
            </div>
          </div>
          <div class="filter-option flex-center-between">
            <div
              class="option"
              :class="tabIndex == 1 ? 'active' : ''"
              @click="changeOption(1)"
            >
              {{ $t('zong-he') }}
            </div>
            <div
              class="option flex-center"
              :class="tabIndex == 2 ? 'active' : ''"
              @click="changeOption(2)"
            >
              {{ $t('xiao-shou-liang') }}
              <div class="flex-column-center icon">
                <i
                  class="el-icon-caret-top"
                  :class="tabIndex == 2 && sortType == 'asc' ? 'active' : ''"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="tabIndex == 2 && sortType == 'desc' ? 'active' : ''"
                ></i>
              </div>
            </div>
            <div
              class="option flex-center"
              :class="tabIndex == 3 ? 'active' : ''"
              @click="changeOption(3)"
            >
              {{ $t('jia-ge') }}
              <div class="flex-column-center icon">
                <i
                  class="el-icon-caret-top"
                  :class="tabIndex == 3 && sortType == 'asc' ? 'active' : ''"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="tabIndex == 3 && sortType == 'desc' ? 'active' : ''"
                ></i>
              </div>
            </div>
            <div
              class="option flex-center"
              :class="tabIndex == 4 ? 'active' : ''"
              @click="changeOption(4)"
            >
              {{ $t('shang-xin') }}
              <div class="flex-column-center icon">
                <i
                  class="el-icon-caret-top"
                  :class="tabIndex == 4 && sortType == 'asc' ? 'active' : ''"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="tabIndex == 4 && sortType == 'desc' ? 'active' : ''"
                ></i>
              </div>
            </div>
          </div>
          <List
            v-if="goodsList.length > 0"
            v-model="loading"
            :finished="finished"
            :finished-text="$t('common.noMore')"
            @load="onLoad"
          >
            <goodsList :list="goodsList"></goodsList>
          </List>
        </div>
      </div>
    </div>
    <categoryBar
      class="shop"
      :showPopup="showPopup"
      @submit="chooseCategory"
      @cancel="cancel"
    ></categoryBar>
  </div>
</template>
<script>
import {
  followShop,
  getShopFollowList,
  getShopList,
  shopProductList,
  addShopRead
} from '@/api/shop'
import '@vant/touch-emulator'
import goodsList from '@/components/goodsList/wapUserList'
import categoryBar from '@/components/category'
import { SwipeItem, Swipe, List } from 'vant'
export default {
  components: {
    goodsList,
    categoryBar,
    SwipeItem,
    Swipe,
    List
  },
  data() {
    return {
      categoryUrl: require('@/assets/imgs/icon-category.png'),
      tabIndex: 1,
      sortType: 'desc',
      shopId: '',
      shopInfo: {},
      form: {
        category: '',
        sort: ''
      },
      hideMenu: false,
      isFollow: false,
      activeIndex: 1,
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      showPopup: false,
      bannerList: [],
      goodsList: []
    }
  },
  computed: {
    categoryList() {
      return this.$store.state.categoryList
    },
    lang() {
      return this.$store.state.lang
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  mounted() {
    this.shopId = this.$route.query.id
    document.body.addEventListener('scroll', this.pageScroll)
    this.initGoods()
    this.init()
    this.initShop()
    this.initCategory()
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.pageScroll)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    initRead() {
      if (!this.isLogin) {
        return
      }
      addShopRead()
    },
    initCategory() {
      this.$store.dispatch('initCategory', this.lang)
    },
    onLoad() {
      this.page.current += 1
      this.initGoods(true)
    },
    initGoods(isAdd) {
      this.loading = true
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        CategoryID: this.form.category ? parseFloat(this.form.category) : null,
        ShopID: parseFloat(this.shopId),
        Sort: this.form.sort
      })
        .then((res) => {
          if (isAdd) {
            this.goodsList = this.goodsList.concat(res.data.Items)
          } else {
            this.goodsList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    initShop() {
      getShopList({
        ID: parseFloat(this.shopId)
      }).then((res) => {
        this.shopInfo = res.data.Items[0]
        let bannerList = []
        if (this.shopInfo.Banner) {
          bannerList.push(this.shopInfo.Banner)
        }
        if (this.shopInfo.Banner1) {
          bannerList.push(this.shopInfo.Banner1)
        }
        if (this.shopInfo.Banner2) {
          bannerList.push(this.shopInfo.Banner2)
        }
        this.bannerList = bannerList
      })
    },
    pageScroll(e) {
      let top = e.target.scrollTop
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (top > 5) {
          this.hideMenu = true
        } else {
          this.hideMenu = false
        }
      }, 100)
      if (top > 100) {
        this.hideMenu = true
      }
    },
    init() {
      if (!this.isLogin) {
        return
      }
      getShopFollowList({
        current: 1,
        pageSize: 100
      }).then((res) => {
        let list = res.data.Items
        let index = list.findIndex((v) => {
          return (v.ShopID = this.shopId)
        })
        this.isFollow = index > -1
      })
    },
    toFollow() {
      if (this.isFollow) {
        return
      }
      followShop({
        ShopID: parseFloat(this.shopId)
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('guan-zhu-cheng-gong'))
          this.isFollow = true
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    changeTab(i) {
      this.activeIndex = i
    },
    currentChange(page) {},
    changeShow(data, i) {
      let show = !data.show
      this.$set(this.categoryList[i], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeShowSub(data, i, index) {
      if (!data.children || data.children.length == 0) {
        return
      }
      let show = !data.show
      this.$set(this.categoryList[i].children[index], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeOption(i) {
      if (i == this.tabIndex) {
        this.sortType = this.sortType == 'asc' ? 'desc' : 'asc'
      } else {
        this.sortType = 'desc'
        this.tabIndex = i
      }
      this.form.sort =
        i == 1
          ? ''
          : i == 2
          ? 'sales'
          : i === 3
          ? this.sortType == 'asc'
            ? 'MaxPrice'
            : 'MinPrice'
          : ''
      this.page.current = 1
      this.initGoods()
    },
    chooseCategory(data) {
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    showAll() {
      this.form.category = ''
      this.page.current = 1
      this.initGoods()
    },
    changeCategory() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>